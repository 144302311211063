//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
export default {
  props: {
    indexData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    url: {
      type: String,
      default: null,
    },
    referer: {
      type: String,
      default: null,
    },
    modalName: {
      type: String,
      default: "productInfo",
    },
  },
  components: {
    XModal: () => import("@/components/XModal"),
    CWinTmp: () => import("@/components/CWinTmp"),
  },
  data() {
    return {
      activeTabs: [],
      inputVisible: false,
      inputValue: "",
      tempTag: "",
      isTagChange: false,
      isRepeatedTag: false,
      loading: false,
      editState: false,
      comWidth: "95%",
      attend: false,
      suggestionLabels: [],
      defaultProductSKUParams: [],
      formData: {
        Id: 0,
        ProductCode: "",
        Name: "",
        Labels: [],
        IsMarketable: false,
        ProductSKUs: [],
        CreateTime: null,
        UpdateTime: null,
      },
      Rules: {
        Name: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
        ],
        Labels: [
          {
            required: true,
            message: "请输入标签",
            trigger: "blur",
          },
        ],
        ProductSKURules: {
          Name: "请输入货品名称",
          Price: "请填写售价",
          Stock: "请填写库存数量",
          Unit: "请填写计件单位",
          ProductParams: "请设置货品参数",
        },
        ProductSKUParamRules: {
          Name: "请输入货品参数名称",
          Value: "请输入货品参数值",
        },
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  filters: {
    truncateText(text) {
      if (!text) {
        return "无货品名称";
      }
      const maxWords = 80;
      if (text.length > maxWords) {
        text = text.substring(0, maxWords) + "...";
      }
      return text;
    },
  },
  methods: {
    checkRule(value, msg) {
      return {
        validator: (rule, v, callback) => {
          if (Array.isArray(value)) {
            if (value.length == 0) {
              callback(new Error(msg));
            } else {
              callback();
            }
          } else if (!value) {
            callback(new Error(msg));
          } else {
            callback();
          }
        },
        required: true,
        trigger: ["blur", "change"],
      };
    },
    addProductSKU() {
      this.formData.ProductSKUs.push({
        ProductParams: [],
      });
      this.activeTabs = [];
      this.activeTabs.push(this.formData.ProductSKUs.length - 1);
      this.$nextTick(() => {
        this.$refs.editForm.$el.parentElement.scrollTo(
          0,
          this.$refs.editForm.$el.scrollHeight
        );
      });
    },
    addProductSKUParam(productSKU) {
      productSKU.ProductParams.push({});
    },
    deleteProductSKU(productSKU) {
      var index = this.formData.ProductSKUs.indexOf(productSKU);
      this.formData.ProductSKUs.splice(index, 1);
    },
    deleteProductSKUParam(productSKU, productSKUParam) {
      var index = productSKU.ProductParams.indexOf(productSKUParam);
      productSKU.ProductParams.splice(index, 1);
    },
    addSuggestionLabel(label) {
      this.inputValue = label;
      this.handleInputConfirm();
    },
    queryLabels(queryString, cb) {
      cb([]);
    },
    queryDefaultProductSKUParams(queryString, cb) {
      var params = [];
      this.defaultProductSKUParams
        .filter((m) => (queryString ? m.indexOf(queryString) != -1 : true))
        .forEach((m) => {
          params.push({ value: m });
        });
      cb(params);
    },
    productSKUParamChange(productParam, value) {
      productParam.Suggestions = [];
      if (value) {
        productParam.Suggestions = this.$D.LISTByKey(value).map((m) => m.key);
      }
    },
    productSKUParamSelect(productParam, select) {
      productParam.Suggestions = [];
      var value = select.value;
      if (value) {
        productParam.Suggestions = this.$D.LISTByKey(value).map((m) => m.key);
      }
    },
    handleClose(tag) {
      this.formData.Labels.splice(this.formData.Labels.indexOf(tag), 1);
    },
    showInput() {
      this.tempTag = "";
      this.inputVisible = true;
      this.inputValue = "";
      this.isTagChange = false;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      this.isRepeatedTag = false;
      let inputValue = this.inputValue;
      //去除空格
      inputValue = inputValue.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
      if (inputValue == "") {
        this.inputVisible = false;
        return;
      }
      //判断新增的值是否重复
      if (
        this.formData.Labels.indexOf(inputValue) != -1 &&
        this.tempTag != inputValue
      ) {
        this.isRepeatedTag = true;
        this.$message({
          message: "警告!不允许添加重复标签！",
          type: "warning",
          duration: "1000",
        });
        return;
      } else {
        this.isRepeatedTag = false;
      }
      //判断是否修改原有的值，是则替换修改好的值，否则新增
      if (this.isTagChange) {
        this.formData.Labels[this.formData.Labels.indexOf(this.tempTag)] =
          this.inputValue;
        this.inputVisible = false;
        return;
      }
      if (inputValue) {
        this.formData.Labels.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    changeValue(tag) {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
      this.inputValue = tag;
      this.tempTag = tag;
      this.isTagChange = true;
    },
    opened() {
      this.suggestionLabels = this.$D
        .LISTByKey("商品推荐标签")
        .map((m) => m.key);
      this.defaultProductSKUParams = this.$D
        .LISTByKey("货品默认参数")
        .map((m) => m.key);
      this.getProductDetail();
    },
    getProductDetail() {
      if (!this.indexData.row) {
        return;
      }
      this.loading = true;
      var params = {
        Id: this.indexData.row.Id,
      };
      this.$http
        .post("/Product/Details.ashx", params)
        .then((resp) => {
          if (resp.res == 0) {
            this.formData = resp.data;
          } else {
            this.$message({
              message: `获取商品信息失败`,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: `获取商品信息失败`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 改变窗口状态的，搭配index页 添加和编辑按钮
    changeEditState() {
      var canEdit = true;
      if (!canEdit) {
        this.indexData.type = "";
        this.indexData.name = "商品详情";
      } else {
        var isNew = false;
        if (!this.indexData.row) {
          isNew = true;
        }
        this.indexData.type = `${isNew ? "Add" : "Edit"}`;
        this.indexData.name = `${!isNew ? "编辑商品" : "添加商品"}`;
      }
      this.editState = this.indexData.type === "Add" ? true : false;
    },
    // 提交... 细节
    submiting() {
      if (this.formData.ProductCode) {
        this.$refs.winTmp.toggleComfirm();
      } else {
        this.$refs.winTmp.toggleSubmit();
      }
    },
    // 子组件提交共同的接口
    comSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.submiting();

          if (!this.formData.Id) {
            this.$http
              .post("/Product/Create.ashx", this.formData)
              .then((res) => {
                if (res.res == 0) {
                  this.$message({
                    message: `添加商品成功`,
                    type: "success",
                  });
                  this.submiting();
                  this.$modal.hide("productInfo");
                  this.$emit("eventComfirm");
                } else {
                  this.submiting();
                }
              })
              .catch(() => {
                this.$message({
                  message: `添加商品失败`,
                  type: "error",
                });
                this.submiting();
              });
          } else {
            this.$http
              .post("/Product/Update.ashx", this.formData)
              .then((result) => {
                if (result.res == 0) {
                  this.$message({
                    message: `修改商品成功`,
                    type: "success",
                  });
                  this.submiting();
                  this.$modal.hide("productInfo");
                  this.$emit("eventComfirm");
                } else {
                  this.submiting();
                }
              })
              .catch(() => {
                this.$message({
                  message: `修改商品失败`,
                  type: "error",
                });
                this.submiting();
              });
          }
        } else {
          return false;
        }
      });
    },
    // 子组件事件3
    editClick() {
      this.$refs.editForm.clearValidate();
      if (this.editState === false) this.changeEditState();
    },
    // 关闭弹层前，重置数据
    beforeClose() {
      this.attend = false;
      this.loading = false;
      Object.assign(this.$data, this.$options.data());
      this.$router.replace({});
    },
    setLoading(val) {
      this.loading = val;
    },
  },
};
